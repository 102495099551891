module.exports = [{
      plugin: require('/workspace/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-122474281-1","head":true},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/workspace/node_modules/gatsby-plugin-netlify-cms/gatsby-browser'),
      options: {"plugins":[],"modulePath":"/workspace/src/cms/cms.js"},
    },{
      plugin: require('/workspace/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
